import './Funcion.css';

const Funcion = () => {
  return (
    <div className="funcion-container-fluid" id='funcion'>
      <div className="container funcion">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <h2 className="cmo-funciona mb-4">Cómo funciona</h2>
            <div className="funcion-steps">
              <div className="d-flex mb-3">
                <img className="icon" alt="Registrate" src="./images/user-cirlce-add.svg" />
                <div className="ms-3">
                  <h4 className="registrate">Regístrate</h4>
                  <p className="cre-tu-cuenta">Crea tu cuenta en minutos y personaliza tu perfil.</p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <img className="icon" alt="Explorá" src="./images/global-search.svg" />
                <div className="ms-3">
                  <h4 className="registrate">Explora</h4>
                  <p className="cre-tu-cuenta">Utiliza nuestro potente buscador y el mapa interactivo para descubrir los mejores espacios de trabajo en la ciudad.</p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <img className="icon" alt="Reservá" src="./images/calendar-tick.svg" />
                <div className="ms-3">
                  <h4 className="registrate">Reserva</h4>
                  <p className="cre-tu-cuenta">Selecciona el espacio y la fecha que prefieras. Confirmamos tu reserva al instante.</p>
                </div>
              </div>
              <div className="d-flex">
                <img className="icon" alt="Disfrutá tu día" src="./images/building-4.svg" />
                <div className="ms-3">
                  <h4 className="registrate">Disfruta tu día</h4>
                  <p className="cre-tu-cuenta">Dirígete al espacio, trabaja con comodidad y aprovecha al máximo tu día en un entorno productivo.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center px-0"> 
            <img className="img-fluid img-extend" alt="Funcion" src="./images/funcion.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Funcion;

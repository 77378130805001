import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer container-fluid">
      <div className="row text-center">
        <div className="col-12 mb-3">
          <p className="follow-us">Síguenos en redes sociales</p>
        </div>
        <div className="col-12 mb-3">
          <div className="d-flex justify-content-center gap-3">
            <img className="group-icon" alt="Instagram" src="./images/ig.svg" />
            <img className="group-icon" alt="LinkedIn" src="./images/linkedin.svg" />
          </div>
        </div>
        <div className="col-12">
          <p className="profile">© 2024 nomad. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import './Servicios.css';

const Servicios = () => {
  return (
    <div className="container servicios" id='servicios'>
      <div className="row">
        <div className="col-12 col-md-6">
          <img className="img-fluid" alt="Servicios" src="./images/servicios.svg" />
        </div>
        <div className="col-12 col-md-6">
          <div className="text-md-left">
            <h2 className="mb-4">Servicios de nomad</h2>
            <ul className="list-unstyled">
              <li>
                <strong>Búsqueda inteligente</strong>
                <p>Filtra espacios según tus necesidades: ubicación, amenities, tipo de espacio, velocidad de internet, precio, ¡y más!</p>
              </li>
              <li>
                <strong>Mapa interactivo</strong>
                <p>Visualiza todos los espacios en un mapa dinámico para encontrar el lugar más cercano o el que mejor se adapte a tu rutina diaria.</p>
              </li>
              <li>
                <strong>Reservas fáciles y rápidas</strong>
                <p>Elige tu espacio, selecciona la fecha y hora, y confirma tu reserva en segundos. Recibe confirmaciones y recordatorios directamente en la app.</p>
              </li>
              <li>
                <strong>Detalles completos del espacio</strong>
                <p>Descubre cada espacio con fotos, reseñas, lista de servicios, y contacto directo para obtener toda la información que necesitas antes de reservar.</p>
              </li>
              <li>
                <strong>Gestión de cuenta personal</strong>
                <p>Administra tus reservas, favoritos, y preferencias desde tu perfil. Mantén un historial de tus espacios visitados y encuentra rápidamente tus lugares favoritos.</p>
              </li>
            </ul>
            <a className="btn btn-primary mt-4" href="#descarga">Explora espacios de trabajo</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicios;

import './Descarga.css';

const Descarga = () => {
  return (
    <div className="container descarga" id='descarga'>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <h2 className="title">Descarga nomad hoy</h2>
          <p><strong>¿Te encuentras listo para trabajar desde cualquier lugar?</strong></p>
          <p>Descarga la app y comienza a explorar todos los espacios de trabajo en tu ciudad. La productividad está a solo un clic de distancia.</p>
          <div>
            <img className="w-50 qr" alt="QR" src="./images/QR.png" />
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <img className="img-fluid" alt="Descarga" src="./images/descarga.svg" />
        </div>
      </div>
    </div>
  );
};

export default Descarga;

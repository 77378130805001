import './Caracteristicas.css';

const Caracteristicas = () => {
  return (
    <div className="caracteristicas">
      <div className='container'>
        <div className="row justify-content-center mb-5">
          <div className="col-12 text-center">
            <h2 className="mb-3">Descubre el lugar perfecto para trabajar</h2>
            <p>¿Cansado de buscar el espacio de trabajo ideal?</p>
          </div>
          <div className="col-6 text-center">
            <p>Con nomad puedes explorar fácilmente todos los lugares de coworking en tu ciudad, comparar servicios, y encontrar el espacio que mejor se adapte a tus necesidades. <span className='green'>¡Todo en un solo lugar!</span></p>
          </div>
        </div>

        <div className="row text-center">
          <div className="col-12 col-md-4 mb-4">
            <img className="img-fluid mb-3" alt="Espacios verificados" src="./images/verify.svg" />
            <h4>Espacios verificados</h4>
            <p>Cada lugar en nuestra plataforma ha sido cuidadosamente seleccionada para ofrecer una experiencia de calidad.</p>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <img className="img-fluid mb-3" alt="Reserva en segundos" src="./images/calendar-tick.svg" />
            <h4>Reserva en segundos</h4>
            <p>Haz tu reserva directamente desde la app en pocos pasos.</p>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <img className="img-fluid mb-3" alt="Explora en mapa y lista" src="./images/routing.svg" />
            <h4>Explora en mapa y lista</h4>
            <p>Encuentra espacios por ubicación o a través de filtros avanzados como servicios, precios y disponibilidad.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Caracteristicas;

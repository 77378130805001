import './Banner.css';

const Banner = () => {
  return (
    <div className="container banner">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <div>
            <h1 className="title">Encuentra tu espacio de trabajo ideal en la ciudad</h1>
            <h2 className="subtitle">Trabaja donde quieras, cuando quieras</h2>
            <p className="subtitle mt-4">Con <span className='green'>nomad</span>, accede a una <span className='green'>red centralizada de coworking, cafés, y espacios de trabajo</span> diseñados para freelancers, nómadas digitales, startups, y profesionales que buscan flexibilidad y productividad.</p>
          </div>
        </div>
        <div className="col-12 col-md-6 text-center">
          <img className="img-fluid" alt="Banner" src="./images/banner.jpg" />
        </div>
      </div>
    </div>
  );
};

export default Banner;

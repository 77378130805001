import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
import Banner from "./components/Banner";
import Caracteristicas from "./components/Caracteristicas";
import Servicios from "./components/Servicios";
import Funcion from "./components/Funcion";
import Descarga from "./components/Descarga";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Header />
      <Banner />
      <Caracteristicas />
      <Servicios />
      <Funcion />
      <Descarga />
      <Footer />
    </div>
  );
}

export default App;
